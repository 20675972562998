function toAuthors(indexValue) {
  let authors = null;

  if (indexValue) {
    if (Array.isArray(indexValue)) {
      authors = indexValue.map(author => toAuthor(author));
    }
    else {
      const authorsArray = indexValue.split(',');
      authors = authorsArray.map(author => toAuthor(author));
    }
  }
  return authors;
}

function toFirstAuthor(indexValue) {
  let firstAuthor = null;

  if (indexValue && Array.isArray(indexValue) && indexValue.length > 0) {
    firstAuthor = toAuthor(indexValue[0]);
  }

  return firstAuthor;
}

function toAuthor(indexValue) {
  let author = null;

  if (indexValue) {
    author = {
      // TODO: check if new author inputTemplate works
      inputTemplate: 'Author',
      // inputTemplate: 'com.tm.Author',
      contentName: indexValue,
      contentId: '',
    };
  }

  return author;
}

export { toAuthors, toFirstAuthor, };
