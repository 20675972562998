const HeydayConfig = Object.freeze({
  affId: '2409',
  autocompleteMinCharacters: 2,
  searchResultsPage: '/search-results',
  recipesSearchResultPage: '/food/food-search-results',
  maxWordsOneParagraph: 45,
  maxWordsTwoParagraphs: 30,
});

export default HeydayConfig;
