// @flow
/* global window document */

import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';
import HeydayConfig from '../Heyday/HeydayConfig';
import ClickArea, { type SizeType, } from '../ClickArea/ClickArea';
import IconSearch from '../Icon/icons/IconSearch';
import MultiSectionAutoComplete from '../Heyday/AutoComplete/MultiSectionAutoComplete';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import {
  type SuggestionSelected,
  type IndexConfiguration,
} from '../Algolia/AutoComplete/autocompleteTypes';
import EventTracker from '../../utils/EventTracker';
import { urlToBrightspotId, } from '../Heyday/translators';

type SearchBoxPropsType = {
  id: ?string,
  name: string,
  defaultQuery: ?string,
  action: string,
  affId: string,
  domain: string,
  multiSection: Boolean,
  indexConfiguration: IndexConfiguration | Array<IndexConfiguration>,
  searchInputRef: ?{ current: ?HTMLInputElement, },
  inputPlaceholder: ?string,
  localStorageHistoryKey: ?string,

  submitOnSelect: ?boolean,
  onSubmit: ?(event: SyntheticEvent<HTMLElement>, query: ?string) => void,
  onSuggestionSelected: ?(event: SyntheticEvent<HTMLElement>, data: SuggestionSelected) => void,
  miscStyles: ?StyleProps,
  autocompleteContainerMiscStyles: ?StyleProps,
  inputMiscStyles: ?StyleProps,
  suggestionsContainerMiscStyles: ?StyleProps,
  buttonMiscStyles: ?StyleProps,
  buttonSize: SizeType,
  queryMinLength: number,
};

const addQueryToHistory = (query, localStorageHistoryKey) => {
  if (window.localStorage) {
    try {
      let searchHistory = window.localStorage.getItem(localStorageHistoryKey)
        ? JSON.parse(window.localStorage.getItem(localStorageHistoryKey))
        : [];
      if (Array.isArray(searchHistory)) {
        if (searchHistory.includes(query)) {
          searchHistory = searchHistory.filter(item => item !== query);
        }
        searchHistory.unshift(query);
        window.localStorage.setItem(localStorageHistoryKey, JSON.stringify(searchHistory));
      }
    }
    catch (error) {
      console.error(error);
    }
  }
};

const formStyle = ({ theme, miscStyles, }) => ({
  display: 'flex',
  position: 'relative',
  extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
});

// HEYDAY

function SearchBoxHeyday(props: SearchBoxPropsType) {
  const { css, theme, } = useFela({ miscStyles: props.miscStyles, });
  const localRef: { current: ?HTMLInputElement, } = React.useRef(null);
  const inputRef = props.searchInputRef || localRef;
  const formRef: { current: HTMLFormElement | null, } = React.useRef(null);

  const getInputValue = () => {
    const input = inputRef.current;
    const value = input ? input.value : null;

    return value;
  };

  const onSubmit = ({ event, biAction, }, actionSent) => {
    const inputValue = getInputValue();
    !actionSent && biAction && biAction({
      actionCode: 144,
      feature: 'Search Field',
      featureType: 'Content',
      campaignName: props.action && props.action === HeydayConfig.recipesSearchResultPage ? 'Food' : 'General',
      campaignDetails: 'Enter',
      pageType: 'Search',
      additionalInfo: {
        searchTerm: inputValue,
      },
    });

    if (props.localStorageHistoryKey) {
      addQueryToHistory(inputValue, props.localStorageHistoryKey);
    }
    if (props.onSubmit) {
      props.onSubmit(event, inputValue);
    }
  };

  const onSuggestionSelected = ({ event, data, biAction, }) => {
    if (props.onSuggestionSelected) {
      props.onSuggestionSelected(event, data);
    }

    event.preventDefault();
    if (data?.suggestion?.pd?.isAuthor && !!data?.suggestion?.pd?.publicUrl) {
      biAction && biAction({
        actionCode: 171,
        feature: 'Search Field',
        featureType: 'Content',
        campaignName: 'General',
        campaignDetails: 'Writer',
        pageType: 'Search',
        additionalInfo: {
          searchTerm: data?.suggestion?.pd ? data?.suggestion?.pd.title : undefined,
        },
        writerName: data?.suggestion?.pd ? data?.suggestion?.pd.title : undefined,
        writerId: data?.suggestion?.pd ? urlToBrightspotId(data?.suggestion?.pd.url) : undefined,
      });
      const inputValue = getInputValue();

      if (props.localStorageHistoryKey) {
        addQueryToHistory(inputValue, props.localStorageHistoryKey);
      }

      window.setTimeout(() => {
        document.location.href = data?.suggestion?.pd?.publicUrl;
      }, 150);

      return;
    }
    if (props.submitOnSelect) {
      biAction && biAction({
        actionCode: 144,
        feature: 'Search Field',
        featureType: 'Content',
        campaignName: props.action && props.action === HeydayConfig.recipesSearchResultPage ? 'Food' : 'General',
        campaignDetails: 'Auto complete',
        pageType: 'Search',
        additionalInfo: {
          searchTerm: getInputValue(),
        },
      });
      window.setTimeout(() => {
        onSubmit({ event, biAction, }, true);
        formRef.current && formRef.current.submit();
      }, 150);
    }
    else {
      biAction && biAction({
        actionCode: 144,
        feature: 'Search Field',
        featureType: 'Content',
        campaignName: props.action && props.action === HeydayConfig.recipesSearchResultPage ? 'Food' : 'General',
        campaignDetails: 'Auto complete',
        pageType: 'Search',
        additionalInfo: {
          searchTerm: data && data.suggestionValue ? data.suggestionValue : getInputValue(),
        },
      });
      inputRef.current && inputRef.current.focus();
    }
  };

  const formClassName = css(formStyle);

  return (
    <EventTracker>
      {({ biAction, }) => (
        <form className={formClassName} method="GET" ref={formRef} onSubmit={event => onSubmit({ event, biAction, })} action={props.action}>
          <MultiSectionAutoComplete
            id={props.id}
            name={props.name}
            affId={props.affId}
            domain={props.domain}
            multiSection={props.multiSection}
            refinementMinLength={props.queryMinLength}
            defaultRefinement={props.defaultQuery}
            onSuggestionSelected={(event, data) => onSuggestionSelected({ event, data, biAction, })}
            indexConfiguration={props.indexConfiguration}
            inputRef={inputRef}
            placeholder={props.inputPlaceholder}
            containerMiscStyles={props.autocompleteContainerMiscStyles}
            inputMiscStyles={props.inputMiscStyles}
            suggestionsContainerMiscStyles={props.suggestionsContainerMiscStyles}
          />
          <ClickArea miscStyles={props.buttonMiscStyles} isSubmit size={props.buttonSize}>
            <IconSearch size={props.buttonSize} aria-hidden />
            <VisuallyHidden>{theme.headerSearchI18n.a11yTexts.execSearch}</VisuallyHidden>
          </ClickArea>
        </form>
      )}
    </EventTracker>
  );
}

SearchBoxHeyday.defaultProps = {
  id: null,
  defaultQuery: null,
  action: '',
  searchInputRef: null,
  inputPlaceholder: null,
  submitOnSelect: false,
  onSubmit: () => {},
  onSuggestionSelected: () => {},
  miscStyles: null,
  autocompleteContainerMiscStyles: null,
  inputMiscStyles: null,
  suggestionsContainerMiscStyles: null,
  buttonMiscStyles: null,
  buttonSize: 4,
  localStorageHistoryKey: null,
  queryMinLength: 0,
};

export default React.forwardRef((props: SearchBoxPropsType, ref) => (
  <SearchBoxHeyday {...props} searchInputRef={ref} />
));
