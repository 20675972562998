function toImage(indexValue) {
  let image = null;
  const imageUrl = indexValue ? { alt: '', url: indexValue, } : null;

  if (imageUrl != null) {
    image = {
      photographer: 'none',
      accessibility: '',
      imageType: 'image',
      inputTemplate: 'htz_image_Image',
      contentId: getImageContentId(imageUrl),
      contentName: '',
      kind: 'image',
      files: getImageArray(imageUrl),
    };
  }
  return image;
}

function getImageContentId(indexValue) {
  const contentIdRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
  const matches = contentIdRegex.exec(indexValue.url);
  const contentId = matches?.[0];

  return contentId;
}

function getImageArray(indexValue) {
  return [
    {
      imgName: getImageName(indexValue),
      path: getImageName(indexValue),
      aspects: getImageAspects(indexValue),
    },
  ];
}

function getImageName(indexValue) {
  const polopolyFilenameRegex = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/(.+\.[A-Za-z]{3,4})/;
  const matches = polopolyFilenameRegex.exec(indexValue.url);
  const imageName = matches?.[2] || null;

  return imageName;
}

function getImageAspects(indexValue) {
  return {
    regular: { height: '', width: '', },
    headline: { height: '', width: '', },
    landscape: { height: '', width: '', },
    square: { height: '', width: '', },
    vertical: { height: '', width: '', },
    belgrade: { height: '', width: '', },
  };
}

export { toImage, };
