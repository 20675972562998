/* global window */
// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import config from 'config';
import Router from 'next/router';
import HeydayConfig from '../../../Heyday/HeydayConfig';
import SearchBoxHeyday from '../../../Search/SearchBoxHeyday';
import { toPath, } from '../../../Algolia/AlgoliaList/translators/path';

const formMiscStyles = ({ theme, }) => ({
  gridArea: 'se',
  flexDirection: 'row-reverse',
  backgroundColor: theme.color('white'),
  border: [ '1px', 0, 'solid', theme.color('primary', '-4'), ],
  type: [ { until: 's', value: 1, }, { from: 's', value: 2, }, ],
});

const autocompleteContainerMiscStyles = ({ theme, }) => ({
  flexGrow: 1,
});

const inputMiscStyles = ({ theme, }) => ({
  color: theme.color('primary'),
  outline: 'none',
  width: '100%',
  height: '100%',
  paddingInlineStart: '2rem',
  '::placeholder': {
    color: theme.color('primary'),
  },
});

const suggestionsContainerMiscStyles = ({ theme, }) => ({
  color: theme.color('white'),
  position: 'absolute',
  left: '0',
  right: '0',
  top: '100%',
  width: '100%',
  backgroundColor: theme.color('primary', '+1'),
  zIndex: theme.getZIndex('above', 1),
  type: [ { until: 's', value: 0, }, { from: 's', value: 1, }, ],
});

const buttonMiscStyles = ({ theme, }) => ({
  flexGrow: 0,
  marginInlineStart: 'auto',
  color: theme.color('primary'),
  backgroundColor: theme.color('quaternary'),
  paddingTop: [ { until: 's', value: '1rem', }, { from: 's', value: '1.5rem', }, ],
  paddingBottom: [ { until: 's', value: '1rem', }, { from: 's', value: '1.5rem', }, ],
  paddingInlineStart: [ { until: 's', value: '1rem', }, { from: 's', value: '1rem', }, ],
  paddingInlineEnd: [ { until: 's', value: '1rem', }, { from: 's', value: '1rem', }, ],
});

function onSuggestionSelected(event, { suggestion, suggestionValue, }) {
  if (suggestion.indexName === 'recipe_articles') {
    Router.push(toPath(suggestion.url));
  }
}

export default function FlexoSearchBox() {
  const { theme, } = useFela();
  return (
    <SearchBoxHeyday
      inputPlaceholder={theme.recipesSearchI18n.placeHolder}
      miscStyles={formMiscStyles({ theme, })}
      inputMiscStyles={inputMiscStyles({ theme, })}
      autocompleteContainerMiscStyles={autocompleteContainerMiscStyles({ theme, })}
      suggestionsContainerMiscStyles={suggestionsContainerMiscStyles({ theme, })}
      buttonMiscStyles={buttonMiscStyles({ theme, })}
      buttonSize={[ { until: 's', value: 4, }, { from: 's', value: 5, }, ]}
      onSuggestionSelected={onSuggestionSelected}
      submitOnSelect
      name="q"
      action={HeydayConfig.recipesSearchResultPage}
      affId={HeydayConfig.affId}
      domain={`www.${config.get('domain')},`}
      queryMinLength={0}
      multiSection={false}
    />
  );
}
