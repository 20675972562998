// @flow

import * as React from 'react';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import Hit from './Hit';
import AuthorHit from './AuthorHit';
import AutoComplete from './AutoComplete';
import AutoCompleteSectionDivider from './SectionDivider';

import { type IndexConfiguration, type SuggestionSelected, } from './autocompleteTypes';

type PropsType = {
  id: ?string,
  name: string,
  affId: string,
  domain: string,
  multiSection: Boolean,
  indexConfiguration: IndexConfiguration | Array<IndexConfiguration>,
  refinementMinLength: ?number,
  defaultRefinement: ?string,
  onSuggestionSelected: ?(event: SyntheticEvent<HTMLElement>, data: SuggestionSelected) => void,
  inputRef: ?{ current: ?HTMLInputElement, },
  placeholder: ?string,

  containerMiscStyles: ?StyleProps,
  inputMiscStyles: ?StyleProps,
  suggestionsContainerMiscStyles: ?StyleProps,
};
function MultiSectionAutoComplete({
  id,
  name,
  affId,
  domain,
  multiSection,
  indexConfiguration,
  refinementMinLength,
  defaultRefinement,
  onSuggestionSelected,
  inputRef,
  placeholder,
  containerMiscStyles,
  inputMiscStyles,
  suggestionsContainerMiscStyles,
}: PropsType) {
  /**
   * Gets the component for rendering the suggetion.
   * @param {Object} hit the suggestion object
   */
  const getSuggestionRenderer = hit => {
    if (typeof hit === 'string') { // complete suggestion string
      return Hit;
    }
    if (typeof hit === 'object') {
      if (hit.pd) { // author suggestion object
        return AuthorHit;
      }
      if (hit.res && hit.query) { // partial suggestion object
        return Hit;
      }
    }
    return null;
  };


  /**
   * Extract the value to display of the suggestion
   * @param {string | object} hit the suggestion object
   */
  const getSuggestionValue = hit => {
    if (typeof hit === 'string') { // complete suggestion string
      return hit;
    }
    if (typeof hit === 'object') {
      if (hit.pd && hit.pd.title) { // author suggestion object
        return hit.pd.title;
      }
      if (hit.res && hit.query) { // partial suggestion object
        return completePartialSuggestion(hit.res, hit.query);
      }
    }
    return '';
  };

  function completePartialSuggestion(partial, query) {
    const queryArr = query.split(' ');
    if (queryArr.length > 0) {
      const lastWord = queryArr[queryArr.length - 1];
      if (partial.indexOf(lastWord) === 0) { // partial suggestion contains last word
        queryArr[queryArr.length - 1] = partial;
        return queryArr.join(' ');
      }
    }
    return partial;
  }

  /**
   * Gets the component for rendering the section title.
   */
  const getSectionTitleRenderer = () => AutoCompleteSectionDivider;

  return (

    <AutoComplete
      id={id}
      name={name}
      affId={affId}
      domain={domain}
      refinementMinLength={refinementMinLength}
      multiSection={multiSection}
      getSuggestionRenderer={getSuggestionRenderer}
      getSectionTitleRenderer={getSectionTitleRenderer}
      getSuggestionValue={getSuggestionValue}
      defaultRefinement={defaultRefinement}
      onSuggestionSelected={onSuggestionSelected}
      inputRef={inputRef}
      placeholder={placeholder}
      containerMiscStyles={containerMiscStyles}
      inputMiscStyles={inputMiscStyles}
      suggestionsContainerMiscStyles={suggestionsContainerMiscStyles}
    />
  );
}

MultiSectionAutoComplete.defaultProps = {
  refinementMinLength: 0,
  defaultRefinement: null,
  placeholder: null,
  id: null,
};

export default MultiSectionAutoComplete;
