// @flow
/* global fetch Event */

import * as React from 'react';
import { useFela, } from 'react-fela';
import { useMutation, } from 'react-apollo';
import Recaptcha from 'react-google-invisible-recaptcha';
import { POST_USER_STORY, } from '@haaretz/graphql';

import ListView from '../ListView/NewListView';
import ListViewHeader from '../ListViewHeader/ListViewHeader';
import H from '../AutoLevels/H';
import HtzLink from '../HtzLink/HtzLink';
import IconBack from '../Icon/icons/IconBack';
import Form from '../Form/Form';
import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';

// import BSException from '../BSException/BSException';

type PropsType = {
  contentId: string,
  elementTitle: String,
  promoTitle: string,
  promoText: string,
  storiesLinkText: string,
  storiesLink: string,
};

const alphabeitRx = /^[a-z\u0590-\u05FF\s\-"]{2,}$/i; // alpha-beit chars, space, dash quotes. Minlength of 2 chars

const validators = {
  authorName: value => {
    if (!value || value.trim().length === 0) {
      return false;
    }

    return value.length < 20 && alphabeitRx.test(value);
  },
  location: value => {
    if (!value || value.trim().length === 0) {
      return false;
    }

    return value.length < 20 && alphabeitRx.test(value);
  },
  story: value => {
    if (!value || value.trim().length === 0) {
      return false;
    }

    return value.length > 20;
  },
};

const areasTemplate = [
  {
    until: 'l',
    value: '"he" "content"',
  },
  {
    from: 'l',
    value: '"he content"',
  },
];

const colsTemplate = [
  {
    until: 'l',
    value: '1fr',
  },
  {
    from: 'l',
    value: '1fr 6fr',
  },
];

function wrapperStyle({ theme, }) {
  return {
    backgroundColor: theme.color('white'),
    gridArea: 'content',
    display: 'grid',
    gridGap: '3rem',
    extend: [
      theme.mq(
        { until: 's', },
        {
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '1rem',
          paddingBottom: '2rem',
          gridTemplateRows: 'repeat(5, auto)',
          gridTemplateAreas: `
            "desc"
            "name"
            "location"
            "text"
            "submit"
          `,
        }),
      theme.mq(
        { from: 's', until: 'l', },
        {
          paddingLeft: '4rem',
          paddingRight: '4rem',
          paddingTop: '3rem',
          paddingBottom: '3rem',
          gridTemplateRows: 'repeat(4, auto)',
          gridTemplateAreas: `
            "desc desc"
            "name location"
            "text text"
            "  .  submit"
          `,
        }),
      theme.mq(
        { from: 'l', until: 'xl', },
        {
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingTop: '3rem',
          paddingBottom: '3rem',
          gridTemplateRows: '1fr auto auto',
          gridTemplateColumns: '7fr 5fr 5fr',
          gridTemplateAreas: `
            "desc name location"
            "desc text text"
            " .    .   submit"
          `,
        }),
      theme.mq(
        { from: 'xl', },
        {
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '2rem',
          paddingBottom: '2rem',
          gridTemplateRows: '1fr auto auto',
          gridTemplateColumns: '6fr 0 5fr 5fr',
          gridTemplateAreas: `
            "desc . name location"
            "desc . text text"
            " .   .  .   submit"
          `,
        }),
    ],

  };
}

function moreLinkStyle({ theme, }) {
  return {
    color: theme.color(theme.coronaHomeStory.linkColor),
    display: 'block',
    fontWeight: 700,
    extend: [
      theme.type(-1, { untilBp: 'xl', }),
      theme.type(-2, { fromBp: 'xl', }),
      theme.mq({ from: 's', until: 'l', }, {
        marginTop: '1rem',
      }),
    ],
  };
}

const getDataTestProp = preffix => `coronaStory${preffix ? `-${preffix}` : ''}`;

export default function CoronaHomeStory({ contentId, elementTitle, promoTitle, promoText, storiesLink, storiesLinkText, }: PropsType) {
  const { css, theme, } = useFela();
  const [ state, setState, ] = React.useState('idle');
  const recaptchaRef = React.useRef(null);
  const submitRef = React.useRef(null);
  const [ postUserStory, ] = useMutation(POST_USER_STORY);

  const isBusy = state === 'loading';

  const validateForm = React.useCallback(values => {
    const errors = Object.keys(validators)
      .map((key, index) => {
        let error = null;
        const validator = validators[key];
        if (!validator(values[key])) {
          error = { name: key, order: index, };
        }
        return error;
      })
      .filter(err => !!err);
    return errors;
  }, []);

  const disableRefresh = () => {
    if (typeof window !== 'undefined') {
      window.__HTZ?.cancelRefresh();
    }
  };

  const onSubmit = values => {
    let recaptchaResp = recaptchaRef.current.getResponse();

    if (!recaptchaResp) {
      recaptchaRef.current.execute();
      recaptchaResp = recaptchaRef.current.getResponse();
    }

    if (!recaptchaResp) {
      return;
    }

    setState('loading');

    postUserStory({ variables: {
      input: { ...values, topicId: contentId, recaptcha: recaptchaResp, },
    }, })
      .then(response => response.json())
      .then(() => setState('success'))
      .catch(() => setState('success'));
  };

  return (
    <ListView colTemplate={colsTemplate} areasTemplate={areasTemplate} attrs={{ 'data-test': getDataTestProp(), }}>
      <ListViewHeader title={elementTitle} />
      { state === 'success'
        ? (
          <div
            className={css({
              backgroundColor: theme.color('white'),
              padding: '4rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '42rem',
              extend: [
                theme.type(1),
              ],
            })}
            data-test={getDataTestProp('success-block')}
          >
            <H>{theme.userStoryTopicI18n.successTitle}</H>
            <p>{theme.userStoryTopicI18n.successSubtitle}</p>
            <div className={css({ marginTop: '4rem', })}>
              {`${theme.userStoryTopicI18n.successResetPre} `}
              <button
                type="button"
                className={css({ textDecoration: 'underline', color: theme.color('primary'), })}
                onClick={() => setState('idle')}
                data-test={getDataTestProp('clear')}
              >
                {theme.userStoryTopicI18n.successReset}
              </button>
            </div>
          </div>
        )
        : (
          <Form
            clearFormAfterSubmit={false}
            validate={validateForm}
            onSubmit={values => onSubmit(values)}
            onFocus={disableRefresh}
            disableSubmitOnEnterKeyDown
            className={css(wrapperStyle)}
            attrs={{ 'data-test': getDataTestProp('form'), }}
            render={({ getInputProps, handleSubmit, }) => (
              <React.Fragment>
                <div className={css({
                  gridArea: 'desc',
                  display: 'flex',
                  flexDirection: 'column',
                  '& > *:not(:last-child)': {
                    marginBottom: '2rem',
                  },
                  extend: [
                    theme.mq({ from: 'xl', }, {
                      '& > *:not(:last-child)': {
                        marginBottom: '1rem',
                      },
                    }),
                  ],
                })}
                >
                  <H data-test={getDataTestProp('title')}>
                    {promoTitle}
                  </H>
                  <p data-test={getDataTestProp('subTitle')}>
                    {promoText}
                  </p>
                  <Recaptcha
                    sitekey="6LcC3usUAAAAAByOPVWv3pn9KXAwstot5vYjk1Gb"
                    onResolved={() => {
                      handleSubmit && handleSubmit();
                    }}
                    badge="inline"
                    ref={recaptchaRef}
                    style={{ display: 'none', }}
                  />
                  { (storiesLink && storiesLinkText)
                    ? (
                      <div className={css(moreLinkStyle)} data-test={getDataTestProp('link')}>
                        <HtzLink href={storiesLink}>
                          <IconBack size={2} miscStyles={{ marginInlineEnd: '1rem', }} />
                          {storiesLinkText}
                        </HtzLink>
                      </div>
                    )
                    : null
                  }

                </div>
                <TextInput
                  type="text"
                  label={theme.userStoryTopicI18n.name}
                  wrapperStyle={{ gridArea: 'name', }}
                  requiredText={{
                    long: theme.userStoryTopicI18n.namePlaceholder,
                    short: '*',
                  }}
                  {...getInputProps({
                    name: 'authorName',
                    label: theme.userStoryTopicI18n.name,
                    type: 'text',
                  })}
                  attrs={{ 'data-test': getDataTestProp('name'), }}
                />

                <TextInput
                  type="text"
                  label={theme.userStoryTopicI18n.location}
                  wrapperStyle={{ gridArea: 'location', }}
                  requiredText={{
                    long: theme.userStoryTopicI18n.locationPlaceholder,
                    short: '*',
                  }}
                  {...getInputProps({
                    name: 'location',
                    label: theme.userStoryTopicI18n.location,
                    type: 'text',
                  })}
                  attrs={{ 'data-test': getDataTestProp('location'), }}
                />

                <TextInput
                  type="text"
                  isTextArea
                  height={[ { until: 's', value: 14, }, { from: 's', value: 12, }, ]}
                  label={theme.userStoryTopicI18n.content}
                  wrapperStyle={{ gridArea: 'text', }}
                  attrs={{ 'data-test': getDataTestProp('text'), }}
                  requiredText={{
                    long: theme.userStoryTopicI18n.contentPlaceholder,
                    short: '*',
                  }}
                  {...getInputProps({
                    name: 'story',
                    label: theme.userStoryTopicI18n.content,
                    type: 'text',
                  })}
                />
                <Button
                  variant={theme.coronaHomeStory.form.buttonVariant}
                  isBusy={isBusy}
                  onClick={handleSubmit}
                  miscStyles={{ gridArea: 'submit', justifySelf: 'end', }}
                  attrs={{ 'data-test': getDataTestProp('sendButton'), }}
                  ref={submitRef}
                >
                  {theme.userStoryTopicI18n.send}
                </Button>
              </React.Fragment>
            )}
          />
        )
      }
    </ListView>
  );
}
