// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { borderBottom, } from '@haaretz/htz-css-tools';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Section from '../../../AutoLevels/Section';
import setBiAction from '../../../../utils/setBiAction';
import { DonbotMainTeaser, DonbotTeaser, } from '../Donbot/DonbotView';
import FlexoSearchBox from './FlexoSearchBox';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';

type FlexoViewProps = ListViewProps & { isHp: boolean, };

Flexo.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
  isHp: false,
};

const teasers = [ DonbotMainTeaser, DonbotTeaser, DonbotTeaser, DonbotTeaser, DonbotTeaser, ];
const areasTemplate = [
  {
    until: 'l',
    value: `
    "bt bt"
    "he he"
    "se se"
    "t1 t1"
    "t2 t3"
    "t4 t5"
    `,
  },
  {
    from: 'l',
    value: `
    "bt bt bt bt"
    "he se se se"
    "he t1 t2 t3"
    "he t1 t4 t5"
    `,
  },
];

export default function Flexo({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
  isHp,
}: FlexoViewProps): React.Node {
  const { css, theme, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);
  const { items, extraLinks, rainbowTargetSlot, ...restOfList } = list || {};
  const borderTopClassName = css({
    ...borderBottom('2px', 0, 'solid', theme.color('primary')),
    marginBottom: '-2rem',
    gridArea: 'bt',
    extend: [ theme.mq({ until: 's', }, { display: 'none', }), ],
  });

  if (!(items && items.length)) return null;

  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        gridArea: `t${i + 1}`,
        biAction: setBiAction(i, data, biAction),
        isLazyloadImages,
        theme,
        attrs: isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {},
      });
    }
    return null;
  }

  return (
    <ListView
      marginTop={[ { until: 's', value: '6rem', }, { from: 's', value: '6rem', }, ]}
      areasTemplate={areasTemplate}
      colTemplate={[ { until: 'l', value: '1fr 1fr', }, { from: 'l', value: '2fr 6fr 2fr 2fr', }, ]}
      attrs={{
        'data-test': `flexo${isHp ? 'Hp' : ''}`,
      }}
    >
      {isHp ? null : <div className={borderTopClassName} /> }
      <ListViewHeader
        {...restOfList}
        extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
        biAction={biAction}
        isTopBorderDisabled={!isHp}
        miscStyles={
          isHp
            ? null
            : {
              paddingLeft: [ { until: 's', value: '1rem', }, ],
              paddingRight: [ { until: 's', value: '1rem', }, ],
              marginLeft: [ { until: 's', value: '-2rem', }, ],
              marginRight: [ { until: 's', value: '-2rem', }, ],
              type: [ { from: 'l', value: 5, }, ],
            }
        }
        isSticky={isHp}
        titleMiscStyles={isHp ? null : { marginBottom: [ { from: 'l', value: '4rem', }, ], }}
      />
      { rainbowTargetSlot
        ? (
          <RainbowListPaywallSlot
            id={rainbowTargetSlot}
            gridArea="se / se / t5 / t5"
            onToolRendered={onRainbowToolRendered}
          />
        )
        : null}
      <FlexoSearchBox />
      <Section isFragment>{items.map(itemRenderer)}</Section>
    </ListView>
  );
}
