// @flow
import * as React from 'react';
import { WONG_LIST_QUERY as CORONA_LIST_QUERY, } from '@haaretz/graphql';
import CoronaView from './CoronaView.js';
// Conrad and Wong use the exact same query
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type CoronaWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isDark?: boolean,
}

CoronaWrapper.defaultProps = {
  isDark: false,
};

export default function CoronaWrapper(props: CoronaWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={CORONA_LIST_QUERY} view="Corona">
      {dataProps => (
        <CoronaView
          {...dataProps}
          isDark={props?.isDark}
        />
      )}
    </ListWrapper>
  );
}
