function urlToBrightspotId(url) {
  if (url) {
    const regex = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/g;
    const match = url.match(regex);
    return match ? match[0] : null;
  }
  return null;
}

export { urlToBrightspotId, };
