// @flow
import * as translators from './translators';
import type { TeaserDataType, } from '../../flowTypes/TeaserDataType';

export type TeaserDataKeyTranslatorType =
  | string // teaser-data field name
  | {
      targetProp: string, // name of teaser-data field
      translator: any => any, // function that accepts the source value and return translated value
    };

export type TeaserDataMapperType = {
  [sourceProp: string]: TeaserDataKeyTranslatorType | Array<TeaserDataKeyTranslatorType>,
};

const defaultTeaserDataMapperObject = Object.freeze({
  author: { targetProp: 'authors', translator: translators.toAuthors, },
  url: [ { targetProp: 'contentId', translator: translators.urlToBrightspotId, }, { targetProp: 'representedContent', translator: translators.urlToBrightspotId, }, ],
  img: { targetProp: 'image', translator: translators.toImage, },
  modifyTime: { targetProp: 'lastUpdate', translator: translators.unixTimeToMillis, },
  publicUrl: { targetProp: 'path', translator: translators.toPath, },
  publishTime: { targetProp: 'publishDate', translator: translators.unixTimeToMillis, },
  paragraph: [ 'subtitle', 'subtitleMobile', ],
  highlight: 'highlight',
  title: [ 'title', 'titleMobile', ],
});

function teaserDataMapper(data: Object, mapObj: TeaserDataMapperType): TeaserDataType {
  const teaserData = {
    contentId: 'null.null',
    inputTemplate: 'com.tm.TeaserData',
    path: '',
    representedContent: 'null.null',
    title: '',
    linkTarget: null,
    representedContentType: null,
    ...dataMapper(data, mapObj),
  };

  return teaserData;
}

/**
 * Converts data from Heyday index into a Teaser-Data compatible
 * @param {Object} data - hit data from Heyday index.
 * @param {Object} mapObj - data in a TeaserData compatible structure.
 */
function dataMapper(data: Object, mapObj: TeaserDataMapperType) {
  const target = {};

  for (const key in mapObj) {
    if (Object.hasOwnProperty.call(mapObj, key)) {
      const sourceProp = key;
      const sourceValue = getSourceValue(data, sourceProp);

      let targetMapping = mapObj[key] || key;
      if (!Array.isArray(targetMapping)) {
        targetMapping = [ targetMapping, ];
      }

      targetMapping.forEach(mapping => {
        const targetProp = getTargetProp(mapping);
        const targetTranslator = getTargetTranslator(mapping);

        target[targetProp] = targetTranslator ? targetTranslator(sourceValue) : sourceValue;
      });
    }
  }

  return target;
}

function getSourceValue(data: Object, path: string) {
  let finalValue = data;

  if (path) {
    const keys = path.split('.');

    for (let i = 0; finalValue && i < keys.length; i += 1) {
      const k = keys[i];
      finalValue = finalValue[k];
    }
  }

  return finalValue;
}

function getTargetProp(key: TeaserDataKeyTranslatorType) {
  let targetProp = null;

  if (typeof key === 'string') {
    targetProp = key;
  }
  else if (typeof key === 'object') {
    targetProp = key.targetProp;
  }
  else {
    throw new Error('invalid key type. key is not a TeaserDataKeyTranslatorType');
  }

  return targetProp;
}

function getTargetTranslator(key: TeaserDataKeyTranslatorType) {
  let targetTranslator = null;

  if (typeof key === 'object') {
    targetTranslator = key.translator;
  }

  return targetTranslator;
}

export default teaserDataMapper;
export { defaultTeaserDataMapperObject, };
