// @flow
/* global fetch */

import * as React from 'react';
import { Highlight, } from 'react-instantsearch-dom';
import { useFela, } from 'react-fela';
import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';
import AutoSuggest from 'react-autosuggest';
import { type SuggestionSelected, } from './autocompleteTypes';


type PropsType = {
  id: ?string,
  name: string,
  multiSection: boolean,
  hits: Array<Object>,
  defaultRefinement: ?string,
  currentRefinement: string,
  refine: ?string => void,
  onSuggestionSelected: ?(event: SyntheticEvent<HTMLElement>, data: SuggestionSelected) => void,
  getSuggestionRenderer: ?(Object) => React.ComponentType<any>,
  getSectionTitleRenderer: ?(Object) => React.ComponentType<any>,
  getSuggestionValue: Object => string,
  inputRef: ?{ current: ?HTMLInputElement, },
  placeholder: ?string,

  containerMiscStyles: ?StyleProps,
  inputMiscStyles: ?StyleProps,
  suggestionsContainerMiscStyles: ?StyleProps,
};

// $FlowFixMe
const ReferableInput = React.forwardRef((inputProps, ref) => <input {...inputProps} type="text" ref={ref} />);

function containerRule({ theme, containerMiscStyles, }) {
  return {
    extend: [
      ...(containerMiscStyles ? parseStyleProps(containerMiscStyles, theme.mq, theme.type) : []),
    ],
  };
}

function inputRule({ theme, inputMiscStyles, }) {
  return {
    extend: [ ...(inputMiscStyles ? parseStyleProps(inputMiscStyles, theme.mq, theme.type) : []), ],
  };
}

function suggestionsContainerRule({ theme, suggestionsContainerMiscStyles, }) {
  return {
    extend: [
      ...(suggestionsContainerMiscStyles
        ? parseStyleProps(suggestionsContainerMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function renderInputComponent(inputProps) {
  const { inputRef, ...props } = inputProps;
  return <ReferableInput {...props} ref={inputRef} />;
}

function AutoComplete({
  id,
  name,
  multiSection,
  hits,
  defaultRefinement,
  currentRefinement,
  refine,
  onSuggestionSelected,
  getSuggestionRenderer,
  getSectionTitleRenderer,
  getSuggestionValue,
  inputRef,
  placeholder,
  containerMiscStyles,
  inputMiscStyles,
  suggestionsContainerMiscStyles,
}: PropsType) {
  const [ value, setValue, ] = React.useState(currentRefinement || '');
  const { css, } = useFela({
    containerMiscStyles,
    inputMiscStyles,
    suggestionsContainerMiscStyles,
  });

  React.useEffect(() => {
    if (defaultRefinement) {
      setValue(defaultRefinement);
    }
  }, [ defaultRefinement, ]);

  const containerClassNames = css(containerRule);
  const inputClassNames = css(inputRule);
  const suggestionsContainerClassNames = css(suggestionsContainerRule);

  const onChange = (evt, { newValue, }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value, }) => {
    refine(value);
  };

  const onSuggestionsClearRequested = () => {
    refine();
  };

  const renderSuggestion = (hit, { isHighlighted, }) => {
    const Suggestion = getSuggestionRenderer && getSuggestionRenderer(hit);
    return Suggestion ? <Suggestion hit={hit} isHighlighted={isHighlighted} /> : null;
  };

  const renderSectionTitle = section => {
    const SectionTitle = getSectionTitleRenderer && section && section.length > 0 && !section[0].pd ? getSectionTitleRenderer(section) : null;
    return SectionTitle ? <SectionTitle section={section} /> : null;
  };

  const getSectionSuggestions = section => section;
  const inputProps = {
    placeholder,
    onChange,
    value,
    name,
    inputRef,
    ref: inputRef,
  };

  return (
    <AutoSuggest
      id={id}
      suggestions={hits}
      focusInputOnSuggestionClick={false}
      multiSection={multiSection}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      renderInputComponent={renderInputComponent}
      theme={{
        container: containerClassNames,
        input: inputClassNames,
        suggestionsContainerOpen: suggestionsContainerClassNames,
      }}
    />
  );
}

AutoComplete.defaultProps = {
  id: null,
  onSuggestionSelected: null,
  getSuggestionRenderer: hit => Highlight,
  getSectionTitleRenderer: section => null,
  inputRef: null,
  placeholder: null,
  defaultRefinement: null,
};


async function autocomplete(affiliateId, domain, query, attribute,) {
  const requestJson = {
    affId: affiliateId,
    domain,
    q: query.trimStart(),
    attribute,
  };

  return heydayAutocomplete(requestJson, '/search/c/');
}

async function heydayAutocomplete(requestJson, uri) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestJson),
  };

  try {
    const response = await fetch(`https://heyday.io${uri}`, options);
    if (response.ok) {
      const data = await response.json();

      if (data) {
        /* data.n : author suggestion, object, add boolean indicator isAuthor, then return it.
        data.r: text suggestions, string array.
        Typically returned it as is, but if data.w euqals 1,
        it indicates that the suggestion is a partial match,
        in which case we map each string in the array to an object containing both this suggestion string and the original requested query.
        This is done to enable adding the partial suggestion on top of the original query in the search box (see MultiSectionAutoComplete.js) */

        if (data.n && data.n[0] && data.n[0].pd) {
          data.n[0].pd.isAuthor = true;
        }
        return [ data.n, data.r?.filter(res => res !== '').map(res => (data.w && data.w === 1 ? { res, query: requestJson.q, } : res)), ];
      }
    }
  }
  catch {
    console.warn('Heyday search autocomplete error');
  }

  return null;
}

function validateData(data) {
  if (data && data.length === 1 && typeof data[0] !== 'undefined') {
    return true;
  } if (data && data.length >= 2 && typeof data[0] !== 'undefined' && typeof data[1] !== 'undefined') {
    return true;
  }
  return false;
}

function connectAutoComplete(Component) {
  return ({ affId, domain, refinementMinLength, ...props }: { affId: string, domain: string, refinementMinLength: number, } & PropsType) => {
    const [ hits, setHits, ] = React.useState([ [], [], ]);

    const refine = React.useCallback(value => {
      (refinementMinLength > 0 && value && value.length >= refinementMinLength
        ? autocomplete(affId, domain, value, 'author')
          .then(data => setHits(validateData(data) ? data : [ [], [], ]))
        : setHits([ [], [], ]));
    }, [ affId, domain, refinementMinLength, ]);

    return <Component hits={hits} refine={refine} {...props} />;
  };
}

export default connectAutoComplete(AutoComplete);
