// @flow
import * as React from 'react';
import { BOXY_INVERSE_LIST_QUERY, } from '@haaretz/graphql';
import BoxyView from './BoxyView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type BoxyInverseProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function BoxyInverseWrapper(props: BoxyInverseProps) {
  return (
    <ListWrapper {...props} query={BOXY_INVERSE_LIST_QUERY} view="BoxyInverse">
      {dataProps => (
        <BoxyView
          {...dataProps}
          isColorInverse
        />
      )}
    </ListWrapper>
  );
}
