// @flow
import React from 'react';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import AdSlot from '../../../AdManager/AdSlot';

const areasTemplate = [
  { until: 's', value: '"he" "t1"', },
  {
    from: 's',
    until: 'l',
    value: `
    "he"
    "t1"
    "t2"
    `,
  },
  {
    from: 'l',
    value: `
    "he t1 t2"
    "he t3 t4"
    `,
  },
];

export default function KarivView({
  list,
  isLazyloadImages,
  biAction,
}: ListViewProps) {
  return (
    <ListView
      colTemplate={[ { until: 'l', value: '1fr', }, { from: 'l', value: '2fr 5fr 5fr', }, ]}
      areasTemplate={areasTemplate}
      attrs={{
        'data-test': 'kariv',
      }}
      sectionMiscStyles={list.preventRender ? {
        display: list.preventRender.filter(Boolean).map(preventMq => ({ ...preventMq, value: 'none', })),
      } : null}
    >
      <ListViewHeader
        title={list.title}
        isCommercial
      />
      {Array.isArray(list.dfp) && (
        list.dfp.slice(0, 4).map(item => (
          <AdSlot
            key={item.contentId}
            {...item}
            loadPriority={list.loadPriority}
          />
        ))
      )}
    </ListView>
  );
}
