// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export default gql`
  fragment AdSlot on AdSlot {
    aadUnitPath
    sizes
    sizeMapping
    interstitial
    inlineStyle
    cssClass
    targetUser
  }
`;
