// @flow
import React from 'react';
import gql from 'graphql-tag';
import { adSlot, } from '@haaretz/app-utils';
import KarivView from './KarivView.js';
import ListDataGetter from '../../ListDataGetter';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

const PromotedQuery = gql`
  query PromotedQuery($input: ListInput!) {
    list(input: $input) {
      isLazyloadImages
      loadPriority
      preventRender {
        from
        until
      }
      dfp {
        ...adSlot
      }
    }
  }
  ${adSlot}
`;

export default function Kariv(props: ListDataType) {
  return (
    <ListDataGetter query={PromotedQuery} view="Kariv" {...props}>
      {dataProps => <KarivView {...dataProps} />}
    </ListDataGetter>
  );
}
