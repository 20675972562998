// @flow
/* global window */
import * as React from 'react';
import { useFela, } from 'react-fela';
import teaserDataMapper, { defaultTeaserDataMapperObject, } from '../teaserDataMapper';
import Teaser from '../../Teaser/NewTeaser';
import TeaserContent from '../../TeaserContent/NewTeaserContent';
import HtzLink from '../../HtzLink/HtzLink';
import H from '../../AutoLevels/H';
import EventTracker from '../../../utils/EventTracker';
import { urlToBrightspotId, } from '../translators';

import type { TeaserDataType, } from '../../../flowTypes/TeaserDataType';
import TeaserMedia from '../../TeaserMedia/NewTeaserMedia';
import Image from '../../Image/Image';

type AuthorHitPropsType = {
  hit: Object,
  isHighlighted?: boolean,
};

const hitStyles = ({ theme, isHighlighted, }) => ({
  backgroundColor: isHighlighted ? theme.color('primary') : null,
  paddingTop: '3rem',
  paddingBottom: '3rem',
  paddingInlineStart: '3rem',
  ':hover': {
    backgroundColor: theme.color('primary'),
  },
  ':focus': {
    backgroundColor: theme.color('primary'),
  },
  extend: [ theme.type(0), ],
});

function AuthorHit(props: AuthorHitPropsType) {
  const { hit, isHighlighted, } = props;

  const { css, theme, } = useFela({ isHighlighted, });
  const hitClassName = css(hitStyles);
  const hitAsTeaser: TeaserDataType = React.useMemo(
    () => teaserDataMapper(hit?.pd, defaultTeaserDataMapperObject),
    [ hit, ]
  );

  // eslint-disable-next-line no-shadow
  function onClick({ event, biAction, hit, path, }) {
    event.preventDefault();
    event.stopPropagation();

    biAction && biAction({
      actionCode: 171,
      feature: 'Search Field',
      featureType: 'Content',
      campaignName: 'General',
      campaignDetails: 'Writer',
      pageType: 'Search',
      additionalInfo: {
        searchTerm: hit?.pd ? hit.pd.title : undefined,
      },
      writerName: hit?.pd ? hit.pd.title : undefined,
      writerId: hit?.pd ? urlToBrightspotId(hit.pd.url) : undefined,
    });

    window.location = path;
  }

  if (!hitAsTeaser) return null;

  return (
    <EventTracker>
      {({ biAction, }) => (
        <div className={hitClassName}>
          <HtzLink
            href={hitAsTeaser.path}
            onClick={event => onClick({ event, biAction, hit, path: hitAsTeaser.path, })}
          >
            <Teaser
              backgroundColor="transparent"
              color="white"
              data={hitAsTeaser}
              areasTemplate={hitAsTeaser.image ? "'media content'" : "'content'"}
              gridGap="2rem"
              colTemplate={hitAsTeaser.image ? '8rem 1fr' : 'auto'}
              miscStyles={{
                alignItems: 'center',
              }}
            >
              {hitAsTeaser.image ? (
                <TeaserMedia data={hitAsTeaser} disableAnchor>
                  <Image
                    imgOptions={{ transforms: { width: 48, height: 48, aspect: 'regular', }, }}
                    image={hitAsTeaser.image}
                    miscStyles={{ borderRadius: '50%', overflow: 'hidden', }}
                  />
                </TeaserMedia>
              ) : null}
              <TeaserContent tagName="div">
                <H>
                  <span className={css({ fontWeight: 500, marginEnd: '1rem', })}>{theme.algoliaI18n.searchAuthorsPrefix}</span>
                  {hit.pd.title}
                </H>
              </TeaserContent>
            </Teaser>
          </HtzLink>
        </div>
      )}
    </EventTracker>
  );
}

AuthorHit.defaultProps = {
  isHighlighted: false,
};

export default AuthorHit;
