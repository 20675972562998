// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { GalleryDataType, } from '../../../../flowTypes/GalleryDataType';
import type { HTMLEmbedDataType, } from '../../../../flowTypes/HTMLEmbedDataType';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';

import type { CountdownType, } from '../../../../flowTypes/CountdownType';

import { isImage, isEmbed, isGallery, } from '../../../../utils/validateType.js';
import Image from '../../../Image/Image';
import RelatedLiveUpdates from './RelatedLiveUpdates';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import useGetMediaComponent from '../../../../hooks/useGetMediaComponent';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';

import useGetComponent from '../../../../hooks/GetComponentContext/useGetComponent';
import useOneTime from '../../../../hooks/useOneTime';

type Props = {
  list: ListDataType,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  isLazyloadImages: boolean,
  isDark: boolean,
  countdownObj: ?CountdownType,
};

const areasTeaser = `
"media content"
"media  footer"
"media   links"
`;

const areasStackedTeaser = `
"media media media"
"...   content ..."
"...   footer  ..."
"...   links   ..."
`;

const styles = (isDark, theme) => ({
  areasTemplate: [
    { until: 'l', value: areasStackedTeaser, },
    { from: 'l', until: 'xl', value: areasTeaser, },
    { from: 'xl', value: areasTeaser, },
  ],
  rowTemplate: 'auto auto 1fr',
  colTemplate: [
    { until: 'l', value: '0 auto 0', },
    { from: 'l', until: 'xl', value: '7fr 5fr', },
    { from: 'xl', value: '50% 1fr', },
  ],
  colGap: [
    { until: 's', value: '2rem', },
    { from: 'l', until: 'xl', value: '4rem', },
    { from: 'xl', value: '2rem', },
  ],
  rowGap: [
    { until: 'l', value: '1rem', },
    { from: 'l', value: '2rem', },
  ],
  miscStyles: {
    margin: [ { until: 's', value: '0 -2rem', }, ],
    gridColumnEnd: [
      { from: 'l', value: 'span 2', },
    ],
    width: 'auto',
    ...isDark ? {
      color: 'white',
      position: 'relative',
      marginBottom: [ { until: 's', value: '3.5rem', }, { from: 's', value: '4rem', }, ],
      zIndex: 0,
      ':after': {
        content: '""',
        backgroundColor: theme.color('neutral', '-1'),
        position: 'absolute',
        top: '-4rem',
        left: '-4rem',
        width: 'calc(100% + 8rem)',
        height: 'calc(100% + 8rem)',
        zIndex: -1,
      },
    } : {},
  },
  typeScale: [
    { until: 's', value: 2, },
    { from: 's', until: 'l', value: 6, },
    { from: 'l', until: 'xl', value: 5, },
    { from: 'xl', value: 7, },
  ],
  kickerTypeScale: [ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ],
  kickerMiscStyles: {
    position: [ { until: 'l', value: 'absolute', }, ],
    top: '0',
    start: '0',
    zIndex: 1,
    pointerEvents: 'none',
    marginBottom: '1rem',
    marginInlineStart: [ { until: 's', value: '-2rem', }, ],
    marginInlineEnd: [ { until: 's', value: '-2rem', }, ],
    marginTop: [ { until: 'l', value: '-1rem', }, ],
    transform: [ { until: 'l', value: 'translateY(-100%)', }, ],
  },
  kickerInnerMiscStyles: {
    paddingInlineStart: [ { until: 's', value: '2rem', }, ],
    paddingInlineEnd: [ { until: 's', value: '2rem', }, ],
  },
  footerTypeScale: [
    { until: 's', value: -3, },
    { from: 's', until: 'xl', value: -2, },
    { from: 'xl', value: -3, },
  ],
});

Corona.defaultProps = {
  isLazyloadImages: false,
  isDark: false,
  biAction: null,
  countdownObj: null,
};
export default function Corona({
  isLazyloadImages,
  list: {
    items: [ item, ],
  },
  biAction: rawBiAction,
  biImpression,
  countdownObj,
  isDark,
}: Props): React.Node {
  const { theme, } = useFela();
  const getComponent = useGetComponent();
  const ImageGalleryTeaser = getComponent('imageGalleryTeaser');
  const getMediaComponent = useGetMediaComponent(Image, ImageGalleryTeaser);
  const media = item.media || null;

  useOneTime(item && !!biImpression && typeof biImpression === 'function', setBiImpression(0, item, biImpression));

  const biAction = setBiAction(0, item, rawBiAction);

  const MediaComponent = getMediaComponent(media && media.kind);
  const mediaProps = getMediaProps(media, theme);

  const {
    areasTemplate,
    rowTemplate,
    colTemplate,
    colGap,
    rowGap,
    miscStyles,
    typeScale,
    kickerTypeScale,
    kickerMiscStyles,
    kickerInnerMiscStyles,
    footerTypeScale,
  } = styles(isDark, theme);

  return (
    <Teaser
      areasTemplate={areasTemplate}
      colTemplate={colTemplate}
      rowTemplate={rowTemplate}
      colGap={colGap}
      rowGap={rowGap}
      gridGap={null}
      miscStyles={miscStyles}
      attrs={{
        'data-test': 'corona',
      }}
    >
      <TeaserMedia data={item} onClick={biAction}>
        {mediaProps ? <MediaComponent {...mediaProps} lazyLoad={isLazyloadImages} /> : null}
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        kickerIsBlock
        isH1
        {...item}
        typeScale={typeScale}
        kickerTypeScale={kickerTypeScale}
        kickerMiscStyles={kickerMiscStyles}
        kickerInnerMiscStyles={kickerInnerMiscStyles}
        onClick={biAction}
        wrapperMiscStyles={{ position: 'relative', }}
        {...(countdownObj
          ? {
            showKicker: false,
            countdownObj,
            countdownMiscStyles: kickerMiscStyles,
          }
          : {})}
      />
      <TeaserFooter
        showAuthor
        showLive={!!item.liveUpdates}
        data={item}
        typeScale={footerTypeScale}
        {...isDark ? { commentsColor: [ 'link', 'darkMainBlockRelatedArticles', ], miscStyles: { color: 'white', }, } : {}}
      />
      {item.relatedArticles && (
        <RelatedLiveUpdates
          miscStyles={{
            paddingTop: [ { until: 'l', value: '2rem', }, { from: 'l', value: '1rem', }, ],
            paddingBottom: [ { until: 'm', value: '1rem', }, ],
          }}
          linkToFirstArticle
          relatedArticles={item.relatedArticles}
          isDark={isDark}
          biAction={rawBiAction}
        />
      )}
    </Teaser>
  );
}

// /////////////////////////////////////////////////////////////////////
//                               UTILS                                //
// /////////////////////////////////////////////////////////////////////

const imgOptions = theme => ({
  bps: theme.bps,
  aspect: 'regular',
  sizes: [
    { from: 'xl', size: '604px', },
    { from: 'l', size: '556px', },
    { from: 'm', size: '720px', },
    { from: 's', size: '552px', },
    { size: '100wv', },
  ],
  widths: [ 375, 469, 556, 604, 720, ],
});

function getImageProps(
  media: ImageDataType,
  theme: Object
): Object {
  return {
    data: media,
    imgOptions: getImageAssets(imgOptions(theme)),
  };
}

function getEmbedProps(media: HTMLEmbedDataType): Object {
  return media.inputTemplate === 'Youtube'
    ? {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: {
        ...media.settings,
        controls: '0',
        autoplay: true,
        loop: '1',
        logo: '1',
        startAt: 0,
        related: '0',
        mute: true,
      },
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
      videoId: media.videoId,
    }
    : {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: media.settings,
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    };
}

export function getMediaProps(
  media: ?(ImageDataType | HTMLEmbedDataType | GalleryDataType),
  theme: Object
): ?Object {
  if (media) {
    if (isImage(media)) return getImageProps(media, theme);
    if (isEmbed(media)) return getEmbedProps(media);
    if (isGallery(media)) return { ...media, imgOptions, disableFullScreen: true, };
  }
  return null;
}
