// @flow
import * as React from 'react';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import { DonbotQuery as FlexoQuery, } from '../Donbot/Donbot';
import FlexoView from './FlexoView';
import ListDataGetter from '../../ListDataGetter';

type Props = {
  updateListDuplication: Function,
  variables: {},
  listData: ListDataType,
};

export default function Flexo(props: Props): React.Node {
  return (
    <ListDataGetter query={FlexoQuery} view="Flexo" {...props}>
      {dataProps => <FlexoView {...dataProps} />}
    </ListDataGetter>
  );
}
